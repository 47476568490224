import { useTranslation } from "react-i18next";
import { useGetOpenData, useGetYear } from "../../../hooks/query";
import OchiqmalumotlarItem from "./OchiqmalumotlarItem";
import { Pagination } from "@mantine/core";
import { useEffect, useState } from "react";

const chorak = [
  {
    chorak: "1-CHORAK",
    name: "I-CHORAK",
  },
  {
    chorak: "2-CHORAK",
    name: "II-CHORAK",
  },
  {
    chorak: "3-CHORAK",
    name: "III-CHORAK",
  },
  {
    chorak: "4-CHORAK",
    name: "IV-CHORAK",
  },
];

const Ochiqmalumotlar = () => {
  const [quarters, setQuarters] = useState("");
  const [years, setYears] = useState("");
  const [page, setPages] = useState(1);
  const useGetYears = useGetYear();
  const useGetOpenDataList = useGetOpenData({
    quarter: quarters,
    year: years,
  });
  const { t } = useTranslation();
  const handleChangequart = (event) => {
    setQuarters(event.target.value);
  };
  const handleChange = (event) => {
    setYears(event.target.value);
    setQuarters("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h3 className="text-center font-semibold text-2xl">PF-6247</h3>
      <select
        value={years}
        onChange={handleChange}
        className="px-[20px] py-[6px] mr-[10px] rounded-lg shadow-2xl"
      >
        {useGetYears.data?.map((option, index) => (
          <option
            key={index}
            value={option.id}
            className="px-[20px] py-[6px] mr-[10px]"
          >
            {option.year}
          </option>
        ))}
      </select>

      <select
        value={quarters}
        onChange={handleChangequart}
        className="px-[20px] py-[6px] mr-[10px] shadow-2xl rounded-lg"
      >
        {chorak?.map((option, index) => (
          <option key={index} value={option.chorak}>
            {option.name}
          </option>
        ))}
      </select>
      <div className="bg-white mt-[20px] px-[20px] pt-[20px] shadow-2xl rounded-2xl	">
        {useGetOpenDataList.data?.results.map((item) => (
          <OchiqmalumotlarItem item={item} key={item.id} />
        ))}
        {useGetOpenDataList.data?.results == 0 && (
          <p>{t("Ma'lumot mavjud emas")}</p>
        )}
      </div>
      <Pagination
        pt={28}
        pb={50}
        color="cyan"
        size="lg"
        radius="xl"
        page={page}
        onChange={setPages}
        disabled={page >= 10}
        total={useGetOpenDataList.data?.total_pages}
      />
    </div>
  );
};
export default Ochiqmalumotlar;
