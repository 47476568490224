import { Pagination } from "@mantine/core";
import { useState } from "react";
import { useGetNormativ, useGetNormativCategory } from "../../../hooks/query";
import NormativItem from "./NormativItem";
import { useTranslation } from "react-i18next";

const Normativ = () => {
  const [categories, setCategories] = useState("");
  const { t } = useTranslation();
  const [page, setPages] = useState(1);
  const useGetNormativCategoryList = useGetNormativCategory();
  const useGetNormativList = useGetNormativ({
    category: categories,
  });
  const handleChange = (e) => {
    setCategories(e.target.value);
  };

  return (
    <div>
      <h3 className="text-center font-semibold text-2xl">
        {t("Normativ hujjatlar")}
      </h3>
      <select
        value={categories}
        onChange={handleChange}
        className="px-[20px] py-[6px] mr-[10px] rounded-lg shadow-2xl"
      >
        {useGetNormativCategoryList.data?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
        <option value="">{t("barchasi")}</option>
      </select>

      <div className="bg-white mt-[20px] px-[20px] py-[20px] shadow-2xl rounded-2xl">
        {useGetNormativList.data?.results.map((item) => (
          <NormativItem item={item} key={item.id} />
        ))}
        {useGetNormativCategoryList.data?.results === 0 && (
          <p>{t("Ma'lumot mavjud emas")}</p>
        )}
      </div>
      <div>
        <Pagination
          pt={28}
          pb={50}
          color="cyan"
          size="lg"
          radius="xl"
          page={page}
          onChange={setPages}
          disabled={page >= 10}
          total={useGetNormativList.data?.total_pages}
        />
      </div>
    </div>
  );
};
export default Normativ;
