export const cities = [
  {
    id: 1,
    title: "Angren shahar",
  },
  {
    id: 2,
    title: "Bekobod tumani ",
  },
  {
    id: 3,
    title: "Bekobod shahar",
  },
  {
    id: 4,
    title: "Bo'ka tumani",
  },
  {
    id: 5,
    title: "Bo'stonliq tumani",
  },
  {
    id: 6,
    title: "Chinoz tumani",
  },
  {
    id: 7,
    title: " Chirchiq shahar",
  },
  {
    id: 8,
    title: "Ohangaron tumani ",
  },
  {
    id: 9,
    title: "Olmaliq shahar",
  },
  {
    id: 10,
    title: " Oqqo'rg'on tumani",
  },
  {
    id: 11,
    title: " O'rtachirchiq tumani ",
  },
  {
    id: 12,
    title: "Parkent tumani ",
  },
  {
    id: 13,
    title: "Piskent tumani",
  },
  {
    id: 14,
    title: " Qibray tumani ",
  },
  {
    id: 15,
    title: "Quyichirchiq tumani",
  },
  {
    id: 16,
    title: "Yangiyo'l tumani",
  },
  {
    id: 17,
    title: " Yuqorichirchiq tumani",
  },
  {
    id: 18,
    title: " Zangiota tumani",
  },
  {
    id: 19,
    title: " Nurafshon shahar ",
  },
  {
    id: 20,
    title: " Ohangaron shahar",
  },
];

export const meeting = [
  {
    id: 1,
    title: "Murojaat",
  },
  {
    id: 2,
    title: "Taklif",
  },
  {
    id: 3,
    title: "Shikoyat",
  },
  {
    id: 4,
    title: "Ma'lumot olish",
  },
];
