import { useQuery } from "react-query";
import { request } from "../../services/service-api";

export const useGetMarkaziyApt = ({ page_size }) => {
  return useQuery(["use-get-markaziy-aprt", page_size], async () => {
    const { data } = await request.get(`/main/groupboss/`, {
      params: { page_size },
    });
    return data;
  });
};
