import React from "react";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";

const SeacrhPost = () => {
  const { t } = useTranslation();
  return (
    <div>
      <form className="text-black flex items-center">
        <input
          className="w-[400px] py-[4px] rounded-l border-none "
          placeholder={t(" Izlash...")}
          type="search"
        />
        <div className="bg-gray-300 py-[5px] px-1 rounded-r">
          <CiSearch size={22}/>
        </div>
      </form>
    </div>
  );
};

export default SeacrhPost;
