export const cities = [
    {

    },
    {
      id: 1,
      title: "Bo'ka tuman",
    },
    {
      id: 2,
      title: "Angren shaxar",
    },
    {
      id: 3,
      title: "Bekobod tuman",
    },
    {
      id: 4,
      title: "Chinoz tuman",
    },
    {
      id: 5,
      title: "Olmaliq shaxar",
    },
    {
      id: 6,
      title: "Bekobod shaxar",
    },
    {
      id: 7,
      title: "Piskent tuman",
    },
    {
      id: 8,
      title: "Oqqorg'qon tuman",
    },
    {
      id: 9,
      title: "Ohongoron tuman",
    },
    {
      id: 10,
      title: "Nurafshon shaxar",
    },
    {
      id: 11,
      title: "Bo'stonliq  tuman",
    },
  ];
  export const shaxs = [
    {
      id: 1,
      title: "Jismoniy shaxs",
    },
    {
      id: 2,
      title: "Yuridik shaxs",
    },
    {
      id: 3,
      title: "Yakka tartibdagi tadbirkor",
    },
  ];
  
  export const gender = [
    {
      id: 1,
      title: "Erkak",
    },
    {
      id: 2,
      title: "Ayol",
    },
  ];
  
  export const job = [
    {
      id: 1,
      title: "ish bilan ta'minlangan ",
    },
    {
      id: 2,
      title: "Ishsiz",
    },
    {
      id: 3,
      title: "Nafaqada",
    },
    {
      id: 4,
      title: "Talaba",
    },
  ];
  
  export const meeting = [
    {
      id: 1,
      title: "uchrashuv belgilash ",
    },
    {
      id: 2,
      title: "Murojaat",
    },
    {
      id: 3,
      title: "Taklif",
    },
    {
      id: 4,
      title: "Shikoyat",
    },
    {
      id: 5,
      title: "Ma'lumot olish",
    },
  ];

