import React from "react";
import { Link } from "react-router-dom";

const BudgetItem = ({ item }) => {
  return (
    <div>
      <Link to={`/open-data/budget/${item.id}`} className="font-semibold">{item.title}</Link>
    </div>
  );
};

export default BudgetItem;
