import { useQuery } from "react-query";
import { request } from "../../services/service-api";

export const useGetNormativ = ({category}) => {
  return useQuery(["use-get-normativ-data",category], async () => {
    const { data } = await request.get(`/data/documents/`,{
      params:{category}
    });
    return data;
  });
};
