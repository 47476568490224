import { useQuery } from "react-query";
import { request } from "../../services/service-api";

export const useGetBudgetDetail = ({id}) => {
  return useQuery(["use-get-budget-detail",id], async () => {
    const { data } = await request.get(
      `/data/open-data/budjet/${id}/`,
      {}
    );
    return data;
  });
};
