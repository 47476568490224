import React from "react";
import { useParams } from "react-router-dom";
import { useGetNormativDetail } from "../../../../hooks/query";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { FcCalendar } from "react-icons/fc";

const NormativDetail = () => {
  const { nmid } = useParams();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const useGetNormativDetailList = useGetNormativDetail({
    id: nmid,
  });

  let date = new Date(useGetNormativDetailList.data?.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  console.log(useGetNormativDetailList);

  return (
    <div>
      <h3 className="text-center font-semibold text-2xl my-[20px]">
        {useGetNormativDetailList.data?.title}
      </h3>
      <div className="h-[100vh]">
        {useGetNormativDetailList.data?.post_file ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={useGetNormativDetailList.data?.post_file}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        ) : (
          ""
        )}
      </div>
      <span className="flex items-center gap-2 py-[20px]">
        <FcCalendar size={18} />
        {dateMDY}
      </span>
    </div>
  );
};

export default NormativDetail;
