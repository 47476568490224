import * as z from "zod";

export const formSchemaAnti = z.object({
  subject: z.string().min(4, {
    message: "F.I.SH.ni kiriting",
  }),
  send_email: z.string().min(4, {
    message: "Email kiriting",
  }),
  phone: z.string().min(4, {
    message: "telefon raqamingizni kiriting",
  }),
  address: z.string().min(4, {
    message: "Shahar yoki tumaningizni tanlang",
  }),
  message: z.string().min(4, {
    message: "Murojaat mazmunini kiriting",
  }),
});
