import { useQuery } from "react-query";
import { request } from "../../services/service-api";

export const useGetOpenData = ({quarter, year}) => {
  return useQuery(["use-get-open-data",quarter, year], async () => {
    const { data } = await request.get(`/data/open-data/`,{
        params :{quarter, year}
    });
    return data;
  });
};
