import { useParams } from "react-router-dom";
import { useGetNavExtraDetail } from "../../../../hooks/query/index";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { BiCalendar } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { useTranslation } from "react-i18next";

const NavbarExtraDetail = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const navigate = useNavigate();

  const { nexid } = useParams();
  const useGetPostDetailList = useGetNavExtraDetail({
    id: nexid,
  });
  const { t } = useTranslation;
  let date = new Date(useGetPostDetailList.data?.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()} / ${date.getHours()} : ${date.getMinutes()}`;

  return (
    <div className="mb-[40px] lg:mx-0 md:mx-4 mx-6">
      <button
        onClick={() => navigate(-1)}
        className="lg:flex md:flex hidden items-center gap-1 bg-[#15616D] text-white pr-4 px-3 rounded py-1 hover:bg-[#164863] transition-all duration-200 lg:ml-0 md:ml-4 "
      >
        <TiArrowBack size={26} />
        ortga
      </button>
      <h3 className="text-center my-[30px] lg:text-xl font-semibold">
        {useGetPostDetailList.data?.title}
      </h3>
      <div className="mb-[40px]">
        {useGetPostDetailList.data?.video_file ? (
          <video
            src={useGetPostDetailList.data?.video_file}
            width="1280"
            height="700"
            loop
            controls
          ></video>
        ) : (
          ""
        )}
      </div>

      <p
        dangerouslySetInnerHTML={{
          __html: useGetPostDetailList.data?.html_content,
        }}
        className="mb-[40px]"
      ></p>

      {useGetPostDetailList.data?.post_file ? (
        <div className="h-[100vh] mb-[30px]">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={useGetPostDetailList.data?.post_file}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        </div>
      ) : (
        ""
      )}
      <div className="flex justify-between">
        <p></p>
        <div>
          <p className="font-semibold">
            {useGetPostDetailList.data?.author_news}
          </p>
          <p className="flex items-center justify-center gap-2 mt-2 bg-gray-200 w-[200px] rounded">
            <BiCalendar size={18} />
            {dateMDY}
          </p>
        </div>
      </div>
    </div>
  );
};
export default NavbarExtraDetail;
