import React from "react";
import { Link } from "react-router-dom";
import { useGetEightNews } from "../../hooks/query";
import { GrFormNextLink } from "react-icons/gr";
import AllNewsItem from "./AllNewsItem";
import { Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";

const AllNews = () => {
  const useGetEightNewsList = useGetEightNews();
  const { t } = useTranslation();
  if (useGetEightNewsList.isLoading) {
    return (
      <div className="flex justify-center left-[350px] h-[600px]">
        <Loader color="#014f86" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div className="mt-[50px]">
      <h2 className="font-[700] text-[30px] text-#121221 mb-4">
        {t("So'nggi yangiliklar")}
      </h2>
      <div className="lg:grid grid-cols-4 ">
        {useGetEightNewsList.data?.map((item) => (
          <AllNewsItem item={item} key={item.id} />
        ))}
      </div>
      <Link to="/all-news">
        <span className="flex gap-2 px-1 py-2 hover:bg-[#EEF2FF] bg-gray-200 duration-200 rounded-r-full items-center w-[170px] m-4 ">
          <p>{t("Ko'proq yangilik")} </p>
          <GrFormNextLink size={22} />
        </span>
      </Link>
    </div>
  );
};

export default AllNews;
