import { useTranslation } from "react-i18next";
import bekobod from "../../assets/images/bekobod.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center px-[100px] gap-8 h-[330px]">
      <div className="w-[600px]">
        <img src={bekobod} alt="" />
      </div>
      <div className="w-[865px] h-[170px]">
        <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
          {t("Bekobod shahri haqida")}
        </h4>
        <p>
          <strong className="text-[20px]">Bekobod - </strong>
          Toshkent viloyatidagi shahar (1954-yildan)[2] . Maydoni 0,03 ming km2,
          aholisi 102,0 ming kishi (2023). Bekobod Oʻzbekistonning muhim sanoat
          shahri hisoblanadi. Bu yerda yirik poʻlat zavodi va sement zavodi
          joylashgan. Shuningdek, gʻisht zavodi, goʻsht kombinati, paxta zavodi,
          koʻplab kichik va oʻrta korxonalar bor. Farhod toʻgʻoni va Farhod GES
          shaharning yuqori qismida joylashgan.
        </p>
      </div>
    </div>
  );
};

export default RegionInfo;
