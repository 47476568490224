import React from "react";
import AllNews from "../../pages/allNews/AllNews";
import NewsHero from "../../pages/newsHero/NewsHero";
import UsefulResources from "../../pages/usefulResources/UsefulResources";
import GoToPhoto from "../../pages/goToPhoto/GoToPhoto";
import NewsCarousel from "../../pages/newsCarousel/NewsCarousel";
import Hokim from "../../pages/hokim/Hokim";
import RegionInfo from "../../pages/regionInfo/RegionInfo";
import Test from "../../pages/test/Test";
import Statistika from "../../pages/statistika/Statistika";
import InteraktiveCart from "../../pages/interaktiveCart/InteraktiveCart";

const Home = () => {
  return (
    <div >
      <div className="lg:hidden md:hidden block text-black">
        <Test />
      </div>
      <div className="lg:flex md:flex gap-6 mb-[20px] lg:mx-0 md:mx-4">
        <div className="lg:w-[70%] md:w-[70%] lg:mx-0 md:mx-0 mx-6 shadow-2xl">
          <NewsCarousel />
        </div>
        <div className="bg-[#BFC0C0] rounded-lg lg:w-[30%] lg:mx-0 md:mx-4 mx-6">
          <Hokim />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <NewsHero />
        <AllNews />
      </div>
      <div className="lg:block md:block hidden">
        <Statistika />
        {/* <InteraktiveCart /> */}
      </div>
      <div className="mt-[80px] lg:block md:hidden hidden">
        <RegionInfo />
      </div>
      <GoToPhoto />
      <UsefulResources />
    </div>
  );
};

export default Home;
