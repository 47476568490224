import { useQuery } from "react-query";
import { request } from "../../services/service-api";

export const useGetHokimDetail = ({id}) => {
  return useQuery(["use-get-hokim-detail",id], async () => {
    const { data } = await request.get(
      `/extra/khokim/${id}/>`,
      {}
    );
    return data;
  });
};
