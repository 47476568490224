import React from "react";
import { Link } from "react-router-dom";

const NormativItem = ({ item }) => {
  return (
    <div>
      <Link to={`/ndetail/normativ-hujjatlar/detail/${item.id}`} className="font-semibold">
        {item.title}
      </Link>
    </div>
  );
};

export default NormativItem;
