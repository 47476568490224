import React from "react";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

const Test = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center ">
      <Marquee speed={88} gradient={false}>
        {t("Sayt test holatida ishlamoqda !")}
      </Marquee>
    </div>
  );
};

export default Test;
